<template>
  <sidenav :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" v-if="showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <navbar :minNav="navbarMinimize" v-if="showNavbar" class="mb-0" />
    <app-main class="appmain" />
    <app-footer v-show="showFooter" />
    <!-- <configurator :toggle="toggleConfigurator" :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" /> -->
  </main>
</template>
<script setup>
import Sidenav from "../examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import AppMain from './AppMain.vue'
import { onBeforeMount, computed, getCurrentInstance, ref } from "vue";
const $app = getCurrentInstance().proxy

const isRTL = computed(() => $app.$store.state.isRTL);
const color = computed(() => $app.$store.state.color);
const isAbsolute = computed(() => $app.$store.state.isAbsolute);
const isNavFixed = computed(() => $app.$store.state.isNavFixed)
const navbarFixed = computed(() => $app.$store.state.navbarFixed);
const absolute = computed(() => $app.$store.state.absolute);
const showSidenav = computed(() => $app.$store.state.showSidenav);
const showNavbar = computed(() => $app.$store.state.showNavbar);
const showFooter = computed(() => $app.$store.state.showFooter);
const showConfig = computed(() => $app.$store.state.showConfig);
const hideConfigButton = computed(() => $app.$store.state.hideConfigButton);
const s = ref(10), timer = ref()

const toggleConfigurator = () => {
  $app.$store.commit("toggleConfigurator")
}

const navbarMinimize = () => {
  $app.$store.commit("navbarMinimize")
}

onBeforeMount(() => {
  $app.$store.state.token ? $app.$store.commit("getUser") : $app.$router.push({ name: "SignIn" })
  $app.$store.state.infoTimer = setInterval(() => {
    if (!$app.$store.state.token || $app.$store.state.isOut) clearInterval($app.$store.state.infoTimer)
    $app.$store.state.token ? $app.$store.commit("getUser") : $app.$router.push({ name: "SignIn" })
  }, 30000)
  if (!$app.$store.state.token || $app.$store.state.isOut) return
  $app.$store.state.isTransparent = "bg-transparent";
  const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

  if (window.innerWidth > 1200) {
    if (sidenav)
      sidenav.classList.add("g-sidenav-pinned");
  }

  if ($app.$store.state.userMessageType) return
  let readMessageCount = 0;
  $app.$http.message.listUserMessage({ isRead: 0 }).then(res => {
    res.rows.map(r => {
      $app.$msgbox({
        title: r.title,
        message: '<div class="user_message_content">' + r.content + '</div>' +
          '<div class="user_message_time"><div class="text-center"><p class="font-bold">4399 PAY</p><p>' + r.createTime + '</p></div></div>',
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "确认阅读",
        customClass: 'user_message_box',
        confirmButtonClass: 'user_message_btn',
        beforeClose: (action, instance, done) => {
          if (readMessageCount < 3) {
            if (!timer.value && s.value) timer.value = setInterval(() => {
              if (s.value == 0) {
                instance.confirmButtonText = "我已阅读";
                clearInterval(timer.value)
                timer.value = null
                return
              } else instance.confirmButtonText = s.value + "s";
              s.value--;
            }, 1000)
            if (s.value) return
            else s.value = 10
          }
          
          readMessageCount++
          $app.$store.commit("setUserMessageType", true)
          $app.$store.state.userMessageType = true
          if (action === 'confirm') {
            // instance.confirmButtonLoading = true;
            // instance.confirmButtonText = '处理中...';
            $app.$http.message.readMessage(r.id).then(() => {
              done();
            })
            // setTimeout(() => {
            //   instance.confirmButtonLoading = false;
            // }, 300);
          } else done();
        }
      }).then(r => {
        console.log(r);
      })
    }, 100);
  })

  if ($app.$store.state.messageType) return
  $app.$http.notice.listNotice({ status: 0, noticeType: 4, orderByColumn: "updateTime", isAsc: "DESC" }).then((res) => {
    res.rows.map(r => {
      setTimeout(() => {
        $app.$msgbox({
          title: r.noticeTitle,
          message: '<div class="user_message_content">' + r.noticeContent + '</div>' +
            '<div class="user_message_time"><div class="text-center"><p class="font-bold">4399 PAY</p><p>' + r.createTime + '</p></div></div>',
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          confirmButtonText: "确认阅读",
          customClass: 'user_message_box',
          confirmButtonClass: 'user_message_btn',
          beforeClose: (action, instance, done) => {
            if (!timer.value && s.value) timer.value = setInterval(() => {
              if (s.value == 0) {
                instance.confirmButtonText = "我已阅读";
                clearInterval(timer.value)
                timer.value = null
                return
              } else instance.confirmButtonText = s.value + "s";
              s.value--;
            }, 1000)
            if (s.value) return
            else s.value = 10

            $app.$store.commit("setMessageType", true)
            $app.$store.state.messageType = true
            // instance.confirmButtonLoading = true;
            // instance.confirmButtonText = '处理中...';
            if (action === 'confirm') {
              // $app.$http.message.readMessage(r.noticeId).then(() => {
              //   done();
              // }).finally(() => {
              //   setTimeout(() => {
              //     instance.confirmButtonLoading = false;
              //   }, 300);
              // })
            }
            done();
          }
        }).then(r => {
          console.log(r);
        })
      }, 100);
    })
  })
})


</script>

<style lang="scss">
.appmain {
  margin-top: 56px;
}

.main-content {
  // margin-left: calc(200px + 1rem) !important;
}

@media screen and (max-width: 600px) {
  .appmain {
    margin-top: 92px;
  }
}

.el-notification__content img {
  width: 100%;
}

.user_message_box {
  min-width: 600px;
  max-width: 800px;
  border-radius: 10px;
  padding: 10px 0 20px;

  .el-message-box__content {
    padding: 10px 0 0;
  }

  .el-message-box__btns {
    text-align: center;
    padding-top: 10px;
  }

  .el-message-box__title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  .user_message_content {
    min-height: 100px;
    max-height: 60vh;
    padding: 0 25px;
    outline: none;
    overflow-y: auto;
    font-size: 18px;
    color: initial;
    text-indent: 2em;

    img {
      width: 100%;
    }
  }

  .user_message_time {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    padding-top: 25px;
    padding-right: 25px;
  }

  .user_message_btn {
    font-size: 14px;
    // background-color: #5864C4;
    // border-color: #5864C4;
  }
}

@media screen and (max-width: 600px) {
  .user_message_box {
    min-width: auto;
    max-width: auto;
  }
}
</style>
