<template>
  <div class="card mb-2" :class="directionReverse ? reverseDirection : ''">
    <div :class="color ? 'text-white' : ''">
      <div class="card-header p-3 pt-2 pb-1" :class="`bg-gradient-${color}`" style="border-radius: 0.75rem;">
        <div
          class="icon shadow text-center border-radius-xl position-absolute d-flex align-items-center justify-content-center"
          :class="`bg-gradient-${icon.background} shadow-${icon.background} ${icon.class}`"
          style="width: 50px;height: 50px;"
        >
          <el-icon :color="icon.color" class="no-inherit" size="24">
            <component :is="icon.name" />
          </el-icon>
        </div>
        <div class="pt-1" :class="isRTL ? 'text-start' : 'text-end'">
          <div class="mb-0 text-capitalize font-weight-bolder">{{ title.text }}</div>
          <span class="mb-0 font-weight-bolder" :class="cName" style="font-size: 20px;">
            <span :class="color ? 'text-white' : ''">{{ title. value }}</span>
            <!-- <p class="mb-0 text-xl" style="height: 20px;" v-html="detail"></p> -->
            <!-- <count-to :end-val="title.value" :decimals="2" prefix="$" class="u-font-18"/> -->
          </span>
        </div>
      </div>
      <!-- <hr class="dark horizontal my-0" />  -->
      <!-- <div class="card-footer p-3" :class="`bg-gradient-${color}`" v-if="detail">
        <div :class="isRTL ? 'text-start' : 'text-end'">
          <p class="mb-0" style="height: 20px;" v-html="detail"></p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import CountTo from "vue3-count-to";
import { mapState } from "vuex";
export default {
  components:{CountTo},
  name: "MiniStatisticsCard",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    title: {
      type: Object,
      required: true,
      text: String,
      value: [Number, String],
    },
    detail: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      required: true,
      name: String,
      color: String,
      background: String,
      class: String,
      default: () => ({
        name: "CreditCard",
        color: "text-white",
        background: "success",
      }),
    },
    directionReverse: {
      type: Boolean,
      default: false,
    },
    cName: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
