import request from './request'

// 查询支付方式列表
export function listWalletPayment(query) {
  return request({
    url: '/product/walletPayment/list',
    method: 'get',
    params: query
  })
}

// 查询支付方式详细
export function getWalletPayment(id) {
  return request({
    url: '/product/walletPayment/' + id,
    method: 'get'
  })
}

// 新增支付方式
export function addWalletPayment(data) {
  return request({
    url: '/product/walletPayment',
    method: 'post',
    data: data
  })
}

// 修改支付方式
export function updateWalletPayment(data) {
  return request({
    url: '/product/walletPayment',
    method: 'put',
    data: data
  })
}

// 删除支付方式
export function delWalletPayment(id) {
  return request({
    url: '/product/walletPayment/' + id,
    method: 'delete'
  })
}

// 导出支付方式
export function exportWalletPayment(query) {
  return request({
    url: '/product/walletPayment/export',
    method: 'get',
    params: query
  })
}
