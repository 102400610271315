import request from './request'

// 用户当前会员订阅信息
export function getApiCardVipSubscription() {
  return request({
    url: '/api/card/cardVipSubscription',
    method: 'get'
  })
}

// 查询会员体系集合
export function getApiCardVipList(){
  return request({
    url: '/api/card/cardVips',
    method: 'get'
  })
}
// 查询会员体系集合
export function setVipOrder(data){
  return request({
    url: '/api/card/vipOrders/actions/submit',
    method: 'post',
    data: data
  })
}
// 兑换会员升级券
export function exchangeVipCoupon(id){
  return request({
    url: '/api/card/cardVipSubscription/coupon',
    method: 'post',
    params: {couponId: id}
  })
}

