<template>
  <!-- 支付对话框 -->
  <el-dialog title="支付" v-model="openRecharge" :width="dialogWidth" :show-close="close" :close-on-click-modal="close" :close-on-press-escape="close"
    :class="{ 'usdt_pay_dialog': rechargeForm.paymentType === 'USDT' }" append-to-body class="recharge-dialog-container"
    @close="cancelRechargeDialog" center>
    <div v-if="isRechargeNow && paymentCurrentObj.paymentType === 'alipay'">
      <div class="online_pay_title">支付宝扫一扫支付
        <span style="color: #ed6868;">{{ currencyFilter(rechargeForm.paymentCurrency) }} {{ rechargeForm.paymentAmount
        }}</span>
      </div>
      <div v-loading="qrLoading" class="online_pay_content">
        <div v-if="!rechargeForm.payCodeUrl && !qrLoading"><el-link @click="getAlipayCode"
            type="danger">获取二维码失败，请点击刷新</el-link></div>
        <vueQr v-if="rechargeForm.payCodeUrl" :text="rechargeForm.payCodeUrl" :size="150"></vueQr>
      </div>
      <el-alert title="页面到账金额仅供参考，以提交订单为准。" type="warning" :closable="false" />
    </div>
    <div class="usdt_pay" v-else-if="rechargeForm.paymentType === 'USDT'">
      <el-form ref="formRecharge" :model="rechargeForm" :rules="rules" label-width="80px">
        <div class="u-p-b-10">
          <span class="u-font-29">{{ payAmountFormat(rechargeForm.paymentAmount, 0) }}.</span>
          <span class="text-danger u-font-30">{{ payAmountFormat(rechargeForm.paymentAmount, 1) }}</span>
          <span class="u-font-18 u-p-l-5">USDT</span>
        </div>
        <CountTo :start-val="rechargeForm.targetAmount" :end-val="rechargeForm.targetAmount" :decimals="2" prefix="≈ $"
          class="u-font-18" />
        <div class="u-p-t-5 u-p-b-10">订单号：{{ rechargeForm.id }}</div>
        <!-- <div>必须转入<span class="text-danger">准确的USDT金额</span>，否则无法自动入账！</div> -->
        <div>必须到账<span class="text-danger">准确金额</span>，多付少付均不能入账！</div>
        <!-- <div class="u-p-t-5 u-p-b-2">必须在北京时间<span class="text-danger">{{ rechargeForm.expireDate }}</span>前转入，否则无法自动入账！</div> -->
        <div class="u-p-t-5 u-p-b-2"><span v-if="rechargeForm.expireDate >= 0">支付倒计时：</span><span class="text-danger">{{ expireDate }}</span></div>
        <!-- <div>成功付款后，必须点击<span class="text-danger u-font-20">我已付款</span>按钮，否则无法自动入账！</div> -->
        <el-divider class="u-m-tb-15" />
        <!-- <div>请用任意钱包APP扫描或复制收款地址付款<span class="text-danger">({{ rechargeForm.code }})</span></div> -->
        <div><span class="text-danger">{{ rechargeForm.code }}地址</span></div>
        <div class="usdt_pay_qr" v-if="rechargeForm.paymentRemark" v-html="rechargeForm.paymentRemark"></div>
        <div class="d-flex align-items-center justify-content-center trc20 mb-2">
          <div class="mx-2 mb-2">{{ rechargeForm.paymentAuthCode }}</div>
          <!-- <el-button type="success" plain size="small" v-clipboard:copy="rechargeForm.paymentAuthCode"
            v-clipboard:success="copySuccess">复制地址</el-button> -->
          <el-button type="success" plain size="small" @click="copyText">复制地址</el-button>
        </div>
      </el-form>
    </div>
    <div v-else>
      <el-form ref="formRecharge" :model="rechargeForm" :rules="rules" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="rechargeForm.paymentType === 'wechatpay' ? 12 : 0">
            <div v-if="rechargeForm.paymentRemark" v-html="rechargeForm.paymentRemark"
              class="pay-remark-content pay-remark-wechatpay"></div>
          </el-col>
          <el-col :span="rechargeForm.paymentType === 'USDT' ? 12 : 0">
            <div style="text-align: center;color: #ed6868;font-size: 16px;font-weight: bold;">必须转入准确的金额，否则无法自动入账</div>
            <div v-if="rechargeForm.paymentRemark" v-html="rechargeForm.paymentRemark"
              class="pay-remark-content pay-remark-wechatpay"></div>
          </el-col>
          <el-col :span="rechargeForm.paymentType == 'wechatpay' || rechargeForm.paymentType == 'USDT' ? 12 : 24">
            <div v-if="rechargeForm.paymentType != 'wechatpay' && rechargeForm.paymentType != 'USDT' && isRechargeNow"
              v-html="rechargeForm.paymentRemark" class="pay-remark-content-now"></div>
            <el-form-item label="支付金额" prop="paymentAmount">
              <span class="pr5">{{ currencyFilter(rechargeForm.paymentCurrency) }}</span>
              <span style="color: #ed6868;font-size: 20px;font-weight: bold;">{{ rechargeForm.paymentAmount }}</span>
            </el-form-item>
            <el-form-item label="支付方式" prop="paymentType" v-if="!isRechargeNow">
              <el-select v-model="rechargeForm.paymentId" placeholder="请选择支付方式" style="width: 100%"
                @change="getPaymentInfo">
                <el-option v-for="dict in paymentOptions" :key="dict.id" :label="dict.name" :value="dict.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="!isRechargeNow">
              <div v-if="rechargeForm.paymentRemark && rechargeForm.paymentType != 'wechatpay'"
                v-html="rechargeForm.paymentRemark" class="pay-remark-content"></div>
              <div v-if="rechargeForm.paymentType == 'alipay'" v-loading="qrLoading">
                <div v-if="!rechargeForm.payCodeUrl && !qrLoading">
                  <el-link @click="changepaymentType" type="danger">获取二维码失败，请点击刷新</el-link>
                </div>
                <vueQr v-if="rechargeForm.payCodeUrl" :text="rechargeForm.payCodeUrl" :size="150"></vueQr>
              </div>
            </el-form-item>
            <el-form-item label="上传凭证" prop="sysAudit.auditCertificate" v-if="rechargeForm.paymentType !== 'alipay'">
              <el-upload class="avatar-uploader" :action="upload.url" :show-file-list="false"
                v-model="rechargeForm.sysAudit.auditCertificate" accept=".jpg, .png" :headers="upload.headers"
                :on-success="handleFileSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="rechargeForm.sysAudit.auditCertificate" :src="rechargeForm.sysAudit.auditCertificate"
                  class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="备注说明" v-if="rechargeForm.paymentType !== 'alipay'">
              <el-input type="textarea" v-model="rechargeForm.sysAudit.remark" placeholder="请输入备注说明" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <slot name="footer">
      <div style="text-align: center;" v-if="rechargeForm.paymentType !== 'alipay'">
        <el-button @click="cancelRechargeDialog">取消支付</el-button>
        <el-button type="primary" @click="submitForm">我已付款</el-button>
      </div>
    </slot>
  </el-dialog>
</template>

<script setup>
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import CountTo from "vue3-count-to";
import { ref, getCurrentInstance, watch, computed } from 'vue';
const $app = getCurrentInstance().proxy
const emit = defineEmits();
const props = defineProps({
  rechargeId: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  //是否是快速充值页里的
  isRechargeNow: {
    type: Boolean,
    default: false,
  },
  paymentObj: {
    type: Object,
    default: null,
  },
  close: {
    type: Boolean,
    default: true,
  }
})

const openRecharge = ref(false),
  paymentOptions = ref([]), //支付方式选项
  paymentCurrentObj = ref({
    id: null,
    paymentType: null,
  }),
  // 在线支付 定时器
  checkPayInterval = ref(null),
  rechargeForm = ref({
    id: null, //订单id
    paymentCurrency: "CNY", //支付货币
    targetCurrency: "USD", //目标货币
    targetAmount: null, //目标金额
    paymentAmount: null, //支付金额
    paymentId: null, //支付方式Id
    paymentType: null, //支付方式
    paymentRemark: null, //目标支付方式信息
    sysAudit: {
      auditCertificate: null, //凭证
      remark: null, //备注说明
    },
    paymentAuthCode: null, //银行转账备注代码
    code: null, //微信支付备注代码
    payCodeUrl: null, //支付宝二维码
    expireDate: null, //失效时间
  }),
  // 上传参数
  upload = ref({
    // 是否禁用上传
    isUploading: false,
    // 设置上传的请求头部
    headers: {
      Authorization: "Bearer " + $app.$store.state.token
    },
    url: process.env.VUE_APP_BASE_API + "/common/upload",
    // 上传的文件列表
    fileList: []
  }),
  // 上传的文件列表
  fileList = ref([]),
  qrLoading = ref(true),
  // 表单校验
  rules = ref({
    targetAmount: [{
      required: true,
      message: "支付金额不能为空",
      trigger: "blur"
    }],
    paymentType: [{
      required: true,
      message: "支付方式不能为空",
      trigger: "change"
    }],
    // 'sysAudit.auditCertificate': {
    //   required: true,
    //   message: "请上传凭证",
    //   trigger: "blur"
    // },
    'sysAudit.auditStatus': {
      required: true,
      message: "请选择审核结果",
      trigger: "blur"
    },
  }),
  formRecharge = ref()

watch(() => props.modelValue, (n, o) => {
  if (n) initRecharge();
  openRecharge.value = n;
})

// watch(() => openRecharge, (n, o) => {
//   emit('update:modelValue', n)
// })

const currencyFilter = (val) => {
  if (val === "USD") {
    return "$";
  } else if (val === "CNY") {
    return "￥";
  } else {
    return val
  }
}
const payAmountFormat = (val, index) => {
  if (val) {
    let strArr = val.toString().split(".");
    return strArr[index] ? strArr[index] : "00"
  } else {
    return "";
  }
}
const dialogWidth = computed(() => {
  if ('wechatpay' === rechargeForm.value.paymentType) {
    return '700px';
  } else if ('USDT' === rechargeForm.value.paymentType) {
    return '550px';
  }
  return '600px';
})

const timer = ref(null)
const expireDate = ref('')
const
  initRecharge = async () => {
    //获取充值订单信息
    paymentCurrentObj.value.paymentType = props.isRechargeNow ? props.paymentObj.type : null;
    let res = await $app.$http.walletRecharge.getWalletRecharge(props.rechargeId).catch(() => {
      cancelRechargeDialog();
    })
    if (res) {
      rechargeForm.value.id = props.rechargeId;
      rechargeForm.value.targetAmount = res.data.targetAmount;
      rechargeForm.value.paymentAmount = res.data.paymentAmount;
      rechargeForm.value.paymentCurrency = res.data.paymentCurrency;
      rechargeForm.value.expireDate = res.data.expireDate;
      //倒计时
      const targetDateTime = new Date(rechargeForm.value.expireDate).getTime();
      const currentTime = Date.now();
      if (targetDateTime > currentTime) {
        timer.value = setInterval(() => {
          const remainingMilliseconds = targetDateTime - Date.now();
          if (remainingMilliseconds >= 0) {
            const minutes = Math.floor((remainingMilliseconds / 1000) / 60);
            const seconds = Math.floor((remainingMilliseconds / 1000) % 60);
            expireDate.value = `${minutes} 分 ${seconds} 秒`;
          } else {
            expireDate.value = '账单已过期';
            clearInterval(timer);
          }
        }, 1000);
      } else {
        expireDate.value = '账单已过期';
      }
      //支付方式选项
      $app.$http.walletPayment.listWalletPayment().then(res => {
        paymentOptions.value = res.rows;
        if (props.isRechargeNow) {
          rechargeForm.value.paymentId = props.paymentObj.id;
          getPaymentInfo();
        }
      })
    }
  },

  //充值 - 根据支付方式获取支付信息 通过type判断改变remark内容
  getPaymentInfo = async () => {
    if (!rechargeForm.value.id) {
      return
    }
    //初始化
    rechargeForm.value.payCodeUrl = null;
    rechargeForm.value.paymentAuthCode = null;
    rechargeForm.value.code = null;
    rechargeForm.value.paymentRemark = null;
    rechargeForm.value.paymentType = null;
    clearInterval(checkPayInterval.value);

    let res = null;
    paymentOptions.value.map(async item => {
      let remark = null;
      if (item.id === rechargeForm.value.paymentId) {
        rechargeForm.value.paymentType = item.type;
        if (item.type === "alipay") { //在线支付
          rechargeForm.value.paymentRemark = item.remark
          await getAlipayCode();
        } else { //微信或银行
          //查询订单获取转账备注代码
          res = await $app.$http.walletRecharge.listWalletRecharge({
            code: rechargeForm.value.id
          })
          rechargeForm.value.paymentAuthCode = res.rows[0].paymentAuthCode;
          rechargeForm.value.code = res.rows[0].paymentAuthCode;
          if (item.type === "wechatpay") { //微信支付
            remark = item.remark.replace(/#{code}/ig, rechargeForm.value.code);
            rechargeForm.value.paymentRemark = remark
          } else if (item.type === "USDT") { // USDT
            rechargeForm.value.code = item.accountName
            rechargeForm.value.paymentAuthCode = item.accountCode
            rechargeForm.value.paymentRemark = item.remark
          } else { //银行转账
            remark = item.remark.replace(/#{paymentAuthCode}/, rechargeForm.value.paymentAuthCode);
            let title = '<p style="text-align: center;">请按以下银行汇款信息进行转账充值</p>';
            rechargeForm.value.paymentRemark = title + `<div class="pay-remark-content-left">${remark}</div>`
          }
        }
      }
    })
  },

  //充值 - 获取在线支付二维码
  getAlipayCode = async () => {
    qrLoading.value = true;
    let res = null;
    res = await $app.$http.walletRecharge.getWalletRechargePayInfo(rechargeForm.value.id)
    rechargeForm.value.payCodeUrl = res.data.url;
    qrLoading.value = false;
    if (rechargeForm.value.payCodeUrl) { //二维码不为空时
      checkOnlinePayStatus();
    }
  },
  //充值 - 监听在线支付状态
  checkOnlinePayStatus = () => {
    checkPayInterval.value = setInterval(async () => {
      let payStatus = (await $app.$http.walletRecharge.checkPayStatusWalletRecharge(rechargeForm.value.id));
      if (payStatus === 1) {
        $app.$message.success("支付成功");
        cancelRechargeDialog();
        //刷新钱包
        emit("refreshWallet", true);
      }
    }, 1000);
  },
  // 确定
  submitForm = () => {
    $app.$refs.formRecharge.validate(async valid => {
      if (valid) {
        $app.$http.walletRecharge.payWalletRecharge(rechargeForm.value).then(res => {
          $app.$message.success("已提交，请等待审核");
          cancelRechargeDialog();
          //刷新钱包
          emit("refreshWallet", true);
        });
      }
    });
  },

  //关闭对话框
  cancelRechargeDialog = () => {
    resetForm();
    clearInterval(checkPayInterval.value);
    clearInterval(timer.value);
    expireDate.value = null
    //关闭弹窗
    openRecharge.value = false;
    emit('update:modelValue', false)
  },

  // 文件上传中处理
  handleFileUploadProgress = (event, file, fileList) => {
    upload.value.isUploading = true;
  },
  // 文件上传成功处理
  handleFileSuccess = (response, file, fileList) => {
    upload.value.isUploading = false;
    rechargeForm.value.sysAudit.auditCertificate = response.fileName;
    $app.$message.success(response.msg);
  },
  beforeAvatarUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < 5;
    if (!isLt1M) {
      $app.$message.error('文章缩略图大小不能超过 5MB!');
    }
    return isLt1M;
  },
  resetForm = () => {
    $app.$refs.formRecharge ? $app.$refs.formRecharge.resetFields() : null;
  },
  copySuccess = () => {
    $app.$message.success("收款地址复制成功");
  },
  copyText = () => {
    const textToCopy = rechargeForm.value.paymentAuthCode;
    navigator.clipboard.writeText(textToCopy).then(() => {
      $app.$message.success('收款地址复制成功');
    }).catch(() => {
      $app.$message.error('复制失败');
    });
  }
</script>

<style lang="scss">
.recharge-dialog-container {
  .el-dialog__body {
    padding: 10px 20px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }

  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }

  .pay-remark-content {
    line-height: 1.8em;

    p {
      margin: 0 !important;
    }

    img {
      height: 100px;
    }
  }

  .pay-remark-content-now {
    text-align: center;
    line-height: 1.8em;
    font-size: 1.2em;
    font-weight: bold;

    p {
      margin: 0 !important;
      text-align: center;
    }

    img {
      height: 100px;
    }
  }

  .pay-remark-content-left {
    padding: 13px;

    p {
      margin: 0 !important;
      text-align: left !important;
    }
  }

  .pay-remark-wechatpay {
    text-align: center;

    img {
      width: 100%;
      height: auto !important;
      padding: 0 20px;
    }
  }

  .online_pay_title {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }

  .online_pay_content {
    text-align: center;
    min-height: 100px;

    .el-link.el-link--danger {
      margin: 40px 0;
    }
  }

  .usdt_pay {
    text-align: center;
    font-weight: bold;
    font-size: 16px;

    .usdt_pay_qr {
      text-align: center;

      img {
        width: 240px;
        height: 240px;
      }
    }
  }
}

.usdt_pay_dialog {
  .el-dialog__body {
    padding: 0 20px 20px !important;
  }
}
@media screen and (max-width: 600px) {
  .trc20{
    flex-wrap: wrap !important;
    font-size: 14px !important;
  }
}
</style>
