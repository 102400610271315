import request from './request'

// 查询卡片账单列表
export function listBill(query) {
  return request({
    url: '/card/bill/list',
    method: 'get',
    params: query
  })
}

export function sumBill(query) {
  return request({
    url: '/card/bill/sum',
    method: 'get',
    params: query
  })
}

// 查询卡片账单详细
export function getBill(id) {
  return request({
    url: '/card/bill/' + id,
    method: 'get'
  })
}

// 新增卡片账单
export function addBill(data) {
  return request({
    url: '/card/bill',
    method: 'post',
    data: data
  })
}

// 修改卡片账单
export function updateBill(data) {
  return request({
    url: '/card/bill',
    method: 'put',
    data: data
  })
}

// 删除卡片账单
export function delBill(id) {
  return request({
    url: '/card/bill/' + id,
    method: 'delete'
  })
}

// 导出卡片账单
export function exportBill(query) {
  return request({
    url: '/card/bill/export',
    method: 'get',
    params: query
  })
}

// 同步线上卡片账单
export function syncCardBill(cardId) {
  return request({
    url: '/card/bill/sync',
    method: 'get',
    params: {cardId}
  })
}

// 同步所有卡片线上账单
export function syncCardBillAll(data) {
  return request({
    url: '/card/bill/sync/all',
    method: 'put',
    data: data
  })
}
// 卡片注销差异审核
export function cancelRefund(data) {
  return request({
    url: '/card/bill/cancelRefund',
    method: 'put',
    data: data
  })
}
// 充值入账审核
export function cardTopUpAudit(data) {
  return request({
    url: '/card/bill/audit/topUp',
    method: 'put',
    data: data
  })
}

// 查询首页表格数据
export function getCardReport(id) {
  return request({
    url: '/index/card/report?daySize=' + id,
    method: 'get'
  })
}

export function exportClientBill(query) {
  return request({
    url: '/card/bill/client/export',
    method: 'get',
    params: query
  })
}