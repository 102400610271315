<template>
  <div class="container-fluid content-cardopen">
    <noPower v-if="!$store.state.user.userLevel"></noPower>
    <div class="card p-3 d-flex mt-2" v-else>
      <el-form class="w-100">
        <div class="card-content">
          <span class="text-sm text-white bg-gradient-dark p-3 d-flex" style="border-radius: 8px;">
            <el-icon color="#fff" :size="20" style="margin-right: 10px;">
              <CreditCard />
            </el-icon>
            <span v-if="!cardOpenForm.usedScenes">请选择场景快速开卡</span>
            <span v-else-if="!checkInCardSegmentList.length">暂无卡段</span>
            <span v-else>当前所选信用卡：开卡费用：${{ filterNumber(currentCardSegment.price) || filterNumber(0) }}</span>
          </span>
          <div class="mt-3 mb-3">
            请选择场景
            <el-select v-model="cardOpenForm.usedScenes" class="m-2" placeholder="场景 [ 你的用途 ]" size="large"
              @change="changeUsedScenes">
                <el-option v-for="item in cardType" :key="item.dictLabel" :label="item.dictLabel" :value="item.dictValue" />
            </el-select>
            <span class="text-warning text-xs" v-if="tisShow && !cardOpenForm.usedScenes">*请先选择用卡场景</span>
          </div>
          <div v-if="checkInCardSegmentList.length > 0">
            <div class="tags mt-2 d-flex" style="flex-wrap: wrap;">
              <span class="mt-1" style="padding-right: 10px;">请选择卡段</span>
              <div class="tag-item" v-for="(item, index) in checkInCardSegmentList" :key="index"
                style="padding: 0 10px 10px 0;">
                <el-check-tag :checked="currentCardSegment.id == item.id" v-if="cardOpenForm.usedScenes" size="large"
                  @change="getCurrentCard(item)" style="font-size: 18px;">
                  <div class="d-flex align-items-center" style="height: 20px;">
                    <div>{{ item.code }}</div>
                    <el-tag size="small" type="success" v-if="item.exclusive" class="ms-2">{{getExclusiveLabel(item.exclusive)}}</el-tag>
                  </div>
                </el-check-tag>
                <el-check-tag v-else size="large" style="font-size: 18px;" @click="handleTis">
                  <div class="d-flex align-items-center" style="height: 20px;">
                    <div>{{ item.code }}</div>
                    <el-tag size="small" type="success" v-if="item.exclusive" class="ms-2">{{getExclusiveLabel(item.exclusive)}}</el-tag>
                  </div>
                </el-check-tag>
              </div>
            </div>
            <div class="mt-2">
              <el-form-item v-if="cardOpenForm.usedScenes">
                <div class="d-flex flex-wrap">
                  <div class="d-flex flex-wrap w-100">
                    <div class="mt-1 text-16" style="padding-right: 10px;">请输入金额</div>
                    <div class="d-flex align-items-center flex-wrap" >
                      <div style="width: 200px;">
                        <el-input placeholder="请输入充值金额" v-model="cardOpenForm.rechargeAmount" @input="calculateCost"
                          size="large">
                          <template #prepend>{{ currencyFilter(cardOpenForm.currency) }}</template>
                        </el-input>
                      </div>
                      <div class="text-xs p-1 text-danger" v-if="cardOpenForm.rechargeAmount < currentCardSegment.minOpenAmount">* 充值金额必须大于或等于{{ currentCardSegment.minOpenAmount }}美金</div>
                    </div>
                  </div>
                  <span class="text-danger w-100">最低充值：${{ filterNumber(currentCardSegment.minOpenAmount) || filterNumber(0) }}</span>
                </div>
              </el-form-item>
              <el-form-item @click="handleTis" v-else>
                <div class="d-flex flex-wrap">
                  <div class="d-flex flex-wrap">
                    <div class="zindex" @click="handleTis" style="width: 200px;" >
                      <el-input placeholder="请输入充值金额" v-model="cardOpenForm.rechargeAmount" disabled 
                        size="large">
                        <template #prepend>{{ currencyFilter(cardOpenForm.currency) }}</template>
                      </el-input>
                    </div>
                  </div>
                  <div class="text-danger w-100">最低充值：${{ filterNumber(currentCardSegment.minOpenAmount) || filterNumber(0) }}</div>
                </div>
              </el-form-item>
            </div>
            <!-- <div class="mt-2">
              <el-form-item>
                <div style="width: 380px;">
                  <el-input placeholder="请输入开卡数量" v-model="cardOpenForm.cardProductQuantity" @change="inputProductQuantity" size="large">
                    <template #prepend>开卡数量</template>
                  </el-input>
                </div>
                <div style="margin-left: 20px;">开卡数量<span class="grey">（最多{{ maxCardCount }}张）</span></div>
              </el-form-item>
            </div> -->
            <div class="mt-3" style="border-left: 1px solid #e3306f;padding-left: 10px;">
              <div>
                卡片介绍<br>
                <div class="text-sm" v-html="currentCardSegment.introduction"></div>
              </div>
            </div>
            <div class="mt-3" v-if="cardOpenForm.usedScenes">
              <div class="mt-2 w-100 d-flex card-input">
                <div>
                  剩余卡位：
                  <text v-if="serVipInfo.cardCapacity != null">{{ serVipInfo.cardCapacity }}</text>
                  <text v-else>无限卡位</text>
                </div>
              </div>

              <div class="mt-2 w-100 d-flex card-input">
                <div>
                  可用余额：<text class="text-primary" v-if="wallet.balance < currentCardSegment.minOpenAmount">${{
                    filterNumber(wallet.balance || 0) }}</text>
                  <text v-else>${{ filterNumber(wallet.balance || 0) }}</text>
                </div>
              </div>

              <div class="mt-2 w-100 d-flex card-input">
                <div v-if="tagBox == 1">
                  费用明细：
                  开卡费
                  <span v-if="cardOpenForm.price != null">:
                    {{ currencyFilter(cardOpenForm.currency) }}{{ cardOpenForm.price.toFixed(2) }}</span> +
                  充值金额
                  <span v-if="cardOpenForm.rechargeAmount">:
                    {{ currencyFilter(cardOpenForm.currency) }}{{ parseFloat(cardOpenForm.rechargeAmount).toFixed(2)
                    }}</span>
                  <span v-show="cardOpenForm.totalCharge" class="text-primary"> = {{ currencyFilter(cardOpenForm.currency)
                  }}{{ filterNumber(cardOpenForm.totalCharge) }}</span>
                </div>
                <div v-if="tagBox == 2">
                  费用明细：
                  开卡费
                  <span v-if="cardOpenForm.price != null">:
                    {{ currencyFilter(cardOpenForm.currency) }}{{ cardOpenForm.price.toFixed(2) }}
                    <span v-if="cardOpenForm.cardProductQuantity"> * {{ cardOpenForm.cardProductQuantity }}</span></span>
                  +
                  充值金额
                  <span v-if="showServiceCharge && cardOpenForm.cardProductQuantity">:
                    {{ currencyFilter(cardOpenForm.currency) }}{{ parseFloat(cardOpenForm.rechargeAmount).toFixed(2) }} *
                    {{
                      cardOpenForm.cardProductQuantity }}</span>
                  <span v-show="cardOpenForm.totalCharge" class="text-primary"> = {{ currencyFilter(cardOpenForm.currency)
                  }}{{ filterNumber(cardOpenForm.totalCharge) }}</span>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div style="width: 200px;">
                <el-button class="mb-3 btn btn-md w-100 null " :loading="openLoading" @click="openCardSubmit"  v-if="cardOpenForm.usedScenes" 
                  :disabled="cardOpenForm.rechargeAmount < currentCardSegment.minOpenAmount ? true : flase"
                  :class="cardOpenForm.rechargeAmount < currentCardSegment.minOpenAmount ? 'disabled-but' : 'bg-gradient-primary'"
                  style="display: flex;height: 42px;">
                  立即开卡
                  <span v-show="cardOpenForm.totalCharge">（{{ currencyFilter(cardOpenForm.currency) }}{{
                    filterNumber(cardOpenForm.totalCharge) }}）</span>
                </el-button>
                <material-button class="mb-3 disabled-but" :disabled="true" variant="gradient" color="dark" @click="handleTis" fullWidth v-else>
                  立即开卡
                </material-button>
              </div>
            </div>

            <div class="right w-80 mt-2" style="background: #edeaf1;padding: 20px 20px;">
              <el-tabs v-model="activeName" class="demo-tabs">
                <el-tab-pane label="其他涉及费用" name="first">
                  <div v-html="currentCardSegment.remark"></div>
                </el-tab-pane>
              </el-tabs>
            </div>

          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import MaterialButton from "@/components/MaterialButton.vue";
import noPower from '@/components/noPower'
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { toPinYin } from "@/utils/pinyinUtil.js";
const $app = getCurrentInstance().proxy

const
  activeName = ref("first"),
  tagBox = ref(1),
  serVipInfo = ref({}),
  walletText = ref("可用余额 ( $ num )"),
  wallet = ref({}),
  cardSegmentList = ref([]), //卡段list
  cardSegmentLoading = ref(true),
  currentCardSegment = ref({}),
  cardSegmentTotal = ref(0),
  isPutAway = ref(false), //收起按钮
  subDisabled = ref(false),
  isNoPower = ref(false),
  cardOpenForm = ref({
    cardProductId: null, //卡段id
    cardProductQuantity: 1, //卡片产品数量
    paymentMethod: 0, //支付方式
    rechargeAmount: null, //充值金额rechargeAmount
    price: 0, //开卡费
    serviceCharge: 0, //服务费
    totalCharge: 0, //总费用
    currency: "USD",
    tag: null, //标签
    usedScenes: null
  }),
  // cardRuleForm = ref({
  //   rechargeAmount: [{
  //     required: true,
  //     message: '请输入充值金额',
  //     trigger: ['blur', 'change']
  //   }, {
  //     validator: checkrechargeAmount,
  //     trigger: ['blur', 'change']
  //   }],
  //   cardProductQuantity: [{
  //     required: true, message: '请输入开卡数量', trigger: ['blur', 'change']
  //   },
  //   {
  //     validator: checkProductQuantity,
  //     trigger: ['blur', 'change']
  //   }]
  // }),
  showServiceCharge = ref(false),
  openLoading = ref(false),
  cardHolderList = ref([]),
  currentHolderId = ref(''),
  totalHolder = ref(0),
  cardHolderParams = ref({
    pageNum: 1,
    pageSize: 3,
    tenantId: null,
  }),
  openCardHolder = ref(false),
  maxCardCount = ref(10),
  openCardCount = ref(0),
  multipleOrderCount = ref(1),
  multipleOrderOpen = ref(false),
  multipleOrderPause = ref(false),
  cardExclusiveTypeList = ref([]),
  tisShow = ref(false)

const checkInCardSegmentList = computed(() => {
  if (!cardOpenForm.value.usedScenes) return cardSegmentList.value
  let data = []
  for (let v of cardSegmentList.value) {
    if (v.usedScenes) {
      if (v.usedScenes.split(",").indexOf(cardOpenForm.value.usedScenes) > -1) data.push(v)
    }
  }
  return data
})

const
  handleTis = () => {
    tisShow.value = true
    setTimeout(() => {
      tisShow.value = false
    },2000)
  },
  currencyFilter = (val) => {
    if (val === "USD") {
      return "$";
    } else if (val === "CNY") {
      return "￥";
    } else {
      return val
    }
  },
  feeStrFilter = (val) => {
    if (val) {
      return currencyFilter(cardOpenForm.value.currency) + val;
    } else {
      return;
    }
  },
  exclusiveLabel = (val) => {
    if (val) {
      console.log(cardExclusiveTypeList.value)
      return val.split(',').map(item => {
        let dict = cardExclusiveTypeList.value.find(res => res.value === String(item))
        if (dict) {
          return dict.label
        }
      })
    }
  }

const cardType = ref([])

const
  changeUsedScenes = () => {
    if (checkInCardSegmentList.value.length > 0) {
      currentCardSegment.value = checkInCardSegmentList.value[0];
      cardOpenForm.value.cardProductId = currentCardSegment.value.id;
      cardOpenForm.value.price = parseFloat(currentCardSegment.value.price);
      calculateCost();
    }
  },
  getCardHolderInfo = () => {
    if ($app.$store.state.user.tenantId) {
      cardHolderParams.value.tenantId = $app.$store.state.user.tenantId;
      $app.$http.cardHolder.listCardHolder(cardHolderParams.value).then(response => {
        cardHolderList.value = response.rows;
        totalHolder.value = response.total;
        backfillInfo(cardHolderList.value[0]);
      });
    }
  },
  loadCardInfo = () => {
    cardHolderParams.value.pageSize += 1;
    if (cardHolderParams.value.pageSize >= totalHolder.value) {
      cardHolderParams.value.pageSize = totalHolder.value;
    }
    // getCardHolderInfo();
  },
  openCardHolderClick = () => {
    checkCurrentHolder();
    openCardHolder.value = true;
  },
  //查看当前选中的持卡人表单信息是否有变
  checkCurrentHolder = () => {
    cardHolderList.value.map(item => {
      if (item.id == cardOpenForm.value.cardHolder.id) {
        for (var key in cardOpenForm.value.cardHolder) {
          if (cardOpenForm.value.cardHolder[key] != item[key]) {
            cardOpenForm.value.cardHolder.id = null;
          }
        }
      }
    })
    currentHolderId.value = cardOpenForm.value.cardHolder.id;
  },
  //回填信息
  backfillInfo = (currentHolder) => {
    if (currentHolder) {
      cardOpenForm.value.cardHolder = {
        id: currentHolder.id,
        firstName: currentHolder.firstName, // 名
        lastName: currentHolder.lastName, // 姓
        country: currentHolder.country, //国家
        state: currentHolder.state, // 州
        city: currentHolder.city, //城市
        street: currentHolder.street, //街道地址
        zipCode: currentHolder.zipCode, //邮编
      };
      currentHolderId.value = currentHolder.id;
    }
  },
  submitSelectCard = (currentHolder) => {
    backfillInfo(currentHolder);
    openCardHolder.value = false;
  },
  //获取用户会员信息
  getUserVipInfo = () => {
    cardSegmentLoading.value = true;
    $app.$http.shop.getApiCardVipSubscription().then(res => {
      serVipInfo.value = res.data;
      cardSegmentTotal.value = res.data.cardProductList.length;
      cardSegmentList.value = res.data.cardProductList;
      // console.log(cardSegmentList);
      // cardSegmentList.value.map((r,i) => {
      //   if(r.exclusive){
      //     let type = new RegExp(r.exclusive).test($app.$store.state.user.cardExclusive)
      //     if(!type){
      //       cardSegmentList.value.shift(i)
      //       console.log(cardSegmentList.value);
      //     }
      //   }
      // })
      if (cardSegmentTotal.value > 0) {
        currentCardSegment.value = res.data.cardProductList[0];
        cardOpenForm.value.cardProductId = currentCardSegment.value.id;
        cardOpenForm.value.price = parseFloat(currentCardSegment.value.price);
      }
      cardOpenForm.value.serviceCharge = parseFloat(res.data.cardServiceFeeCoefficient);
    }).finally(() => {
      cardSegmentLoading.value = false;
    })
  },
  getWalletInfo = async () => {
    // 超管钱包为空
    if ($app.$store.state.user.tenantId) {
      // 获取钱包信息
      wallet.value = (await $app.$http.wallet.getWalletByCurrency("USD")).data;
      walletText.value = walletText.value.replace("num", wallet.value.balance);
    } else {
      walletText.value = walletText.value.replace("num", 0);
    }
  },
  //选择当前卡
  getCurrentCard = (item) => {
    currentCardSegment.value = item;
    cardOpenForm.value.cardProductId = currentCardSegment.value.id;
    cardOpenForm.value.price = parseFloat(currentCardSegment.value.price);
    calculateCost();
  },
  //下拉选择卡
  selectCard = () => {
    cardSegmentList.value.map(item => {
      if (item.id == cardOpenForm.value.cardProductId) {
        currentCardSegment.value = item;
        cardOpenForm.value.price = parseFloat(item.price);
        calculateCost();
      }
    })
  },
  //充值金额 - 计算费用明细
  calculateCost = () => {
    let rechargeAmount = parseFloat(cardOpenForm.value.rechargeAmount)
    let cardProductQuantity = parseFloat(cardOpenForm.value.cardProductQuantity)
    if (isNaN(cardOpenForm.value.rechargeAmount)) {//输入含非数值
      cardOpenForm.value.rechargeAmount = rechargeAmount ? rechargeAmount : null;
      return;
    }

    if (rechargeAmount >= 1) {
      if (tagBox.value == 1) {
        cardOpenForm.value.totalCharge = cardOpenForm.value.price + rechargeAmount;
      } else if (tagBox.value == 2) {//批量开卡
        cardOpenForm.value.totalCharge = cardOpenForm.value.price * cardProductQuantity + rechargeAmount * cardProductQuantity;
      }
      showServiceCharge.value = true;
    } else {
      cardOpenForm.value.totalCharge = 0;
      showServiceCharge.value = false;
    }
  },
  inputProductQuantity = () => {
    let cardProductQuantity = parseFloat(cardOpenForm.value.cardProductQuantity)
    if (isNaN(cardOpenForm.value.cardProductQuantity)) {//输入含非数值
      cardOpenForm.value.cardProductQuantity = cardProductQuantity ? cardProductQuantity : null;
      return;
    }
    calculateCost()
  },
  //开卡
  openCardSubmit = async () => {
    if (!await $app.verifySafePwd()) return
    // $app.$refs.cardForm.validate(async valid => {
    //   if (valid) {
    // if (!subDisabled.value) return
    if (cardOpenForm.value.totalCharge > wallet.value.balance) {
      $app.$message.error('钱包余额不足，请先去充值');
    } else {
      openLoading.value = true;
      // checkCurrentHolder();
      if (cardOpenForm.value.cardProductQuantity > 1) {
        multipleOrderOpen.value = true;
        multipleOrderPause.value = false;
        multipleOrderCount.value = cardOpenForm.value.cardProductQuantity;
        if (openCardCount.value >= multipleOrderCount.value) {
          openCardCount.value = 0;
        }
        for (let i = openCardCount.value + 1; i <= multipleOrderCount.value; i++) {
          if (multipleOrderPause.value) {
            if (!multipleOrderOpen.value) {
              openCardCount.value = 0;
            }
            break;
          }
          await submitCardOrder();
          openCardCount.value = i;
        }
        openLoading.value = false;
      } else {
        $app.$http.card.addProductCard(cardOpenForm.value).then(res => {
            openLoading.value = false;
            if (res.data.id) {
            $app.$confirm(`提交成功，『${res.data.id}』开卡中`, {
              confirmButtonText: '查看开卡进度',
              cancelButtonText: '留在当前页',
              type: 'success'
            }).then(() => {
              $app.$router.push({ name: "CardList" });
            })
            getUserVipInfo();
            getWalletInfo();
            //清空表单
            // resetForm();
          }
        }).finally(() => {
          openLoading.value = false;
        })
      }
    }
    //   }
    // })
  },
  submitCardOrder = () => {
    let formData = JSON.parse(JSON.stringify(cardOpenForm.value));
    formData.cardProductQuantity = 1;
    return new Promise((resolve) => {
      $app.$http.card.addProductCard(formData).finally(() => {
        resolve()
      })
      // setTimeout(()=>{
      //   resolve()
      // },1000)
    })
  },
  resetForm = () => {
    cardOpenForm.value = {
      cardProductId: null, //卡段id
      cardProductQuantity: 1, //卡片产品数量
      paymentMethod: 0, //支付方式
      rechargeAmount: '', //充值金额rechargeAmount
      // cardHolder:{
      //   id: null,
      //   firstName: null, // 名
      //   lastName: null, // 姓
      //   country: null, //国家
      //   state: null, // 州
      //   city: null, //城市
      //   street: null, //街道地址
      //   zipCode: null, //邮编
      // },
      price: 0, //开卡费
      serviceCharge: 0, //服务费
      totalCharge: 0, //总费用
      currency: "USD",
      tag: null, //标签
    };
    resetForm("cardForm");
  },
  setTagBox = (index) => {
    tagBox.value = index;
    if (index === 1) {
      cardOpenForm.value.cardProductQuantity = 1;
    }
    calculateCost();
  },
  toPinyin = (val, key) => {
    cardOpenForm.value.cardHolder[key] = toPinYin(val);
  },
  handleMultipleOrderClose = () => {
    multipleOrderOpen.value = false;
    multipleOrderPause.value = true;
    openLoading.value = false;
    openCardCount.value = 0;
    getWalletInfo();
  },
  toOrderList = () => {
    multipleOrderOpen.value = false;
    $app.$router.push({ name: "CardList" })
  }

  const exclusive = ref([])
  const getExclusiveLabel = (val) => {
    
    if (val) {
      let dict = exclusive.value.find(item => item.dictValue === val)
      return dict ? dict.dictLabel : ""
    }
  }

onMounted(async () => {
  if ($app.$store.state.user.userLevel) {
    $app.$http.dict.getDicts("card_used_scenes_type").then((res) => {
      cardType.value = res.data
    })
    getUserVipInfo();
    getWalletInfo();
  }
  $app.$http.dict.getDicts('card_exclusive_type').then(res => {
    exclusive.value = res.data
  })
})
</script>
<style lang="scss" scoped>
.card-content {
  max-width: 1300px;
}

::v-deep .el-tabs__item.is-active {
  color: #7b809a !important;
}

@media screen and (max-width: 500px) {
  .priceinput {
    margin-right: 0 !important;
    width: 100% !important;
  }

  .right {
    width: 100% !important;
  }

  .card-content {
    width: 100%;
  }

  .card-input {
    flex-wrap: wrap;
  }
}</style>