import * as bill from './bill.js'
import * as login from './login.js'
import * as notice from './notice.js'
import * as cardAccount from './cardAccount.js'
import * as cardProduct from './cardProduct.js'
import * as dict from './dict.js'
import * as shop from './shop.js'
import * as wallet from './wallet.js'
import * as walletRecharge from './walletRecharge.js'
import * as walletPayment from './walletPayment.js'
import * as currencyExchange from './currencyExchange.js'
import * as cardHolder from './cardHolder.js'
import * as card from './card.js'
import * as cardAccountOrder from './card/cardAccountOrder.js'
import * as rechargeOrder from './card/rechargeOrder.js'
import * as walletDetail from './product/walletDetail.js'
import * as config from './system/config.js'
import * as user from './system/user.js'
import * as operlog from './operlog.js'
import * as message from './system/message.js'

export default {
  message,
  bill,
  login,
  notice,
  cardAccount,
  cardProduct,
  dict,
  shop,
  wallet,
  walletRecharge,
  walletPayment,
  currencyExchange,
  cardHolder,
  card,
  config,
  cardAccountOrder,
  rechargeOrder,
  walletDetail,
  user,
  operlog
}