import request from './request'

// 新增卡段订单
export function addProductCard(data) {
  return request({
    url: '/api/card/cardAccountOrders/actions/submit',
    method: 'post',
    data: data,
  })
}

// 提醒发货
export function setRemindDeliver(id) {
  return request({
    url: `/api/card/cardAccountOrders/${id}/actions/remindDeliver`,
    method: 'post',
  })
}


