import request from '../request'

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    // url: '/system/user/' + praseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/system/user',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: '/system/user/' + userId,
    method: 'delete'
  })
}

// 导出用户
export function exportUser(query) {
  return request({
    url: '/system/user/export',
    method: 'get',
    params: query
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password
  }
  return request({
    url: '/system/user/resetPwd',
    method: 'put',
    data: data
  })
}
// 用户密码重置
export function resetUserSafePwd(userId, safePassword) {
  const data = {
    userId,
    safePassword
  }
  return request({
    url: '/system/user/resetSafePwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户安全密码修改
export function updateUserSafePwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updateSafePwd',
    method: 'put',
    params: data
  })
}

// 删除用户安全密码
export function delUserSafePwd() {
  return request({
    url: '/system/user/profile/delSafePwd',
    method: 'delete'
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: '/system/user/importTemplate',
    method: 'get'
  })
}

// 查询授权角色
export function getAuthRole(userId) {
  return request({
    url: '/system/user/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole(data) {
  return request({
    url: '/system/user/authRole',
    method: 'put',
    params: data
  })
}

// 修改操作环境保护
export function changeRemoteOperation(remoteOperation) {
  return request({
    url: '/system/user/profile/changeRemoteOperation',
    method: 'put',
    params: {remoteOperation}
  })
}

// 用户余额扣款
export function reduceUserBalance(data) {
  return request({
    url: '/system/user/reduceUserBalance',
    method: 'put',
    params: data
  })
}

// 用户操作密码校验
export function verifySafePwd(safePwd) {
  return request({
    url: '/system/user/profile/verifySafePwd',
    method: 'put',
    params: {safePwd}
  })
}

// 用户操作密码校验
export function updateServiceFee(userId,serviceFee) {
  return request({
    url: '/system/user/profile/updateServiceFee',
    method: 'put',
    params: {userId,serviceFee}
  })
}


// 获取2FA密钥
export function getSecretKey2fa() {
  return request({
    url: '/system/user/profile/getSecretKey2fa',
    method: 'get'
  })
}

// 启用2FA身份验证
export function bindUserSecretKey2fa(secret, code) {
  return request({
    url: '/system/user/profile/bindUserSecretKey2fa',
    method: 'put',
    params: {secret,code}
  })
}

// 验证2FA身份验证Code
export function checkGoogleValidCode(secret, code) {
  return request({
    url: '/system/user/profile/checkGoogleValidCode',
    method: 'put',
    params: {secret,code}
  })
}

// 修改2FA身份验证开关
export function changeSwitch2fa(switch2fa) {
  return request({
    url: '/system/user/profile/changeSwitch2fa',
    method: 'put',
    params: {switch2fa}
  })
}

