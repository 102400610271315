import request from '../request'

// 查询卡片账号订单列表
export function listCardAccountOrder(query) {
  return request({
    url: '/card/cardAccountOrder/list',
    method: 'get',
    params: query
  })
}

// 查询卡片账号订单详细
export function getCardAccountOrder(id) {
  return request({
    url: '/card/cardAccountOrder/' + id,
    method: 'get'
  })
}

// 新增卡片账号订单
export function addCardAccountOrder(data) {
  return request({
    url: '/card/cardAccountOrder',
    method: 'post',
    data: data
  })
}

// 修改卡片账号订单
export function updateCardAccountOrder(data) {
  return request({
    url: '/card/cardAccountOrder',
    method: 'put',
    data: data
  })
}

// 删除卡片账号订单
export function delCardAccountOrder(id) {
  return request({
    url: '/card/cardAccountOrder/' + id,
    method: 'delete'
  })
}

// 删除卡片账号订单
export function cancelCardAccountOrder(id) {
  return request({
    url: `/card/cardAccountOrder/${id}/actions/cancel`,
    method: 'put'
  })
}

// 导出卡片账号订单
export function exportCardAccountOrder(query) {
  return request({
    url: '/card/cardAccountOrder/export',
    method: 'get',
    params: query
  })
}
