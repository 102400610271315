<template>
  <div>
    <noPower v-if="!$store.state.user.userLevel"></noPower>
    <div class="container-fluid" v-else>
      <div class="app-container notice_content">
        <el-tabs type="border-card" v-model="queryParams.status" @tab-change="handleTab" style="border-radius: 0.75rem;overflow: hidden;">
          <el-tab-pane label="系统消息" name="user_msg" v-loading="loading" style="min-height: 50px;">
            <el-collapse v-model="activeName" accordion v-if="userMessageList.length > 0">
              <el-collapse-item :name="Number(item.id)" v-for="(item, index)  in userMessageList" :key="index">
                <template #title>
                  <div class="d-flex justify-content-between w-100 flex-wrap" style="height: auto;">
                    <div class="d-flex align-items-center flex-wrap">
                      <el-tag size="large" type="success" class="mx-2 ms-0" style="width: 72px;" v-if="item.isRead">已处理</el-tag>
                      <el-tag size="large" type="warning" class="mx-2 ms-0" style="width: 72px;" v-else>未处理</el-tag>
                      <span style="font-size: 16px;">{{ item.title }}</span>
                    </div>
                    <span class="mx-2 ms-0 time">{{ item.createTime }}</span>
                  </div>
                </template>
                <div class="ql-editor u-font-15" v-html="item.content"  v-if="item.isRead"></div>
                <div v-else>
                  <div class="ql-editor u-font-15" v-html="item.content"></div>
                  <div class="text-end">
                    <el-button type="success" plain @click="handleReadUserMessage(item.id)">已处理</el-button>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <div class="text-center py-2" v-else-if="userMessageList.length < 1 && !loading">暂无系统消息</div>
          </el-tab-pane>
          <el-tab-pane label="系统通告" name="0" v-loading="loading" style="min-height: 50px;">
            <el-collapse v-model="activeName" accordion v-if="noticeList.length > 0">
              <el-collapse-item :name="item.noticeId" v-for="(item, index) in noticeList" :key="index">
                <template #title>
                  <div class="d-flex justify-content-between w-100 flex-wrap" style="height: auto;">
                    <div class="d-flex align-items-center flex-wrap">
                      <el-tag size="large" type="warning" class="mx-2 ms-0" style="width: 72px;">
                        <i class="iconfont icon-vips" />{{ getType(item.noticeType) }}
                      </el-tag>
                      <span style="font-size: 16px;">{{ item.noticeTitle }}</span>
                    </div>
                    <span class="mx-2 ms-0 time">{{ item.createTime }}</span>
                  </div>
                </template>
                <div class="ql-editor u-font-15" v-html="item.noticeContent"></div>
              </el-collapse-item>
            </el-collapse>
            <div class="text-center py-2" v-else-if="noticeList.length < 1 && !loading">暂无系统消息</div>
          </el-tab-pane>
          <el-tab-pane label="历史系统通告" name="1" v-loading="loading" style="min-height: 50px;">
            <el-collapse v-model="activeName" accordion v-if="noticeList.length > 0">
              <el-collapse-item :name="item.noticeId" v-for="(item, index) in noticeList" :key="index">
                <template #title>
                  <div class="d-flex justify-content-between w-100 flex-wrap">
                    <div class="d-flex align-items-center flex-wrap">
                      <el-tag size="large" type="warning" class="mx-2 ms-0" style="width: 72px;">
                        <i class="iconfont icon-vips" />{{ getType(item.noticeType) }}
                      </el-tag>
                      <span style="font-size: 16px;">{{ item.noticeTitle }}</span>
                    </div>
                    <span class="mx-2 ms-0 time">{{ item.createTime }}</span>
                  </div>
                </template>
                <div class="ql-editor u-font-15" v-html="item.noticeContent"></div>
              </el-collapse-item>
            </el-collapse>
            <div class="text-center py-2" v-else-if="noticeList.length < 1 && !loading">暂无系统消息</div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <el-pagination v-model:current-page="queryParams.pageNum" v-model:page-size="queryParams.pageSize"
          :page-sizes="[10, 20, 50, 100]" small="small" background="background"
          layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script setup>
import noPower from '@/components/noPower'
import { onMounted, ref, getCurrentInstance, watchEffect } from 'vue'
const $app = getCurrentInstance().proxy

onMounted(() => {
  if (!$app.$store.state.user.userLevel) return
  if ($app.$route.query.id) {
    queryParams.value.status = $app.$route.query.status
    activeName.value = Number($app.$route.query.id)
    if(queryParams.value.status === 'user_msg'){
      getUserMessageList()
    }else{
      getList()
    }
  }
  $app.$http.dict.getDicts("sys_notice_type").then(res => {
    noticeType.value = res.data
  })
  getUserMessageList()
})
const
  noticeType = ref([]),
  loading = ref(false),
  // 总条数
  total = ref(0),
  noticeList = ref([]),
  queryParams = ref({
    pageNum: 1,
    pageSize: 10,
    status: "user_msg",
    orderByColumn: 'createTime',
    isAsc: 'DESC'
  }),
  activeName = ref(null),
  userMessageList = ref([])

//分页
const
  handleSizeChange = (v) => {
    queryParams.value.pageSize = v
    if(queryParams.value.status == "user_msg"){
      getUserMessageList()
    }else{
      getList()
    }
  },
  handleCurrentChange = (v) => {
    queryParams.value.pageNum = v
    if(queryParams.value.status == "user_msg"){
      getUserMessageList()
    }else{
      getList()
    }
  },
  getList = () => {
    loading.value = true;
    $app.$http.notice.listNotice(queryParams.value).then(response => {
      noticeList.value = response.rows;
      total.value = response.total;
      loading.value = false;
    });
  },
  getType = (value) => {
    let dict = noticeType.value.find(res => res.dictValue == value);
    if (dict) return dict.dictLabel
    return "";
  },
  handleTab = () => {
    if(queryParams.value.status == "user_msg"){
      getUserMessageList()
    }else{
      getList()
    }
  },
  getUserMessageList = () => {
    loading.value = true
    $app.$http.message.listUserMessage(queryParams.value).then(res => {
      userMessageList.value = res.rows
      total.value = res.total;
      // if(total.value === 0){
      //   queryParams.value.status = '0'
      //   getList()
      // }
      loading.value = false
    })
  },
  handleReadUserMessage = (id) => {
    $app.$http.message.readMessage(id).then(()=>{
      $app.$message.success("操作成功");
      getUserMessageList();
    });
  }



watchEffect(() => {
  if ($app.$route.query.id) {
    activeName.value = Number($app.$route.query.id)
  }
})
</script>
<style lang="scss">
@media screen and (max-width: 600px) {
  .el-collapse-item__header{
    height: auto;
    padding: 10px 0;
    line-height: 1.5;
  }
  .time{
    width: 100%;
  }
}
</style>
