import { createApp } from "vue";
import ElementPlus from 'element-plus'
import zhCn from "element-plus/es/locale/lang/zh-cn";
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import http from "./api";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from "./material-dashboard";
import { verifySafePassword,filterNumber } from "@/utils/ruoyi";

const appInstance = createApp(App);
appInstance.use(ElementPlus, {locale:zhCn})
appInstance.use(store);
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.config.globalProperties.$http = http
appInstance.config.globalProperties.verifySafePwd = verifySafePassword
appInstance.config.globalProperties.filterNumber = filterNumber
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  appInstance.component(key, component)
}
appInstance.mount("#app");
