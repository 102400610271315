<template>
  <div class="input-group" :class="`input-group-${variant} ${getStatus(error, success)}`">
    <label :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <input :id="id" :type="type" class="form-control inputbug" :class="getClasses(size)" :name="props.name"
      v-model="input" :placeholder="props.placeholder" :isRequired="props.isRequired" :disabled="props.disabled"
      ref="myInput" onfocus="this.removeAttribute('readonly');" readonly />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import setMaterialInput from "@/assets/js/material-input.js";
const emit = defineEmits();
const props = defineProps({
  variant: {
    type: String,
    default: "outline",
  },
  label: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
});

const getClasses = (size) => {
  let sizeValue;

  sizeValue = size ? `form-control-${size}` : null;

  return sizeValue;
}, getStatus = (error, success) => {
  let isValidValue;

  if (success) {
    isValidValue = "is-valid";
  } else if (error) {
    isValidValue = "is-invalid";
  } else {
    isValidValue = null;
  }

  return isValidValue;
}

const input = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

onMounted(() => {
  setMaterialInput();
})
</script>
