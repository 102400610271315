<template>
  <div class="chart">
    <canvas :id="id" class="chart-canvas" :height="height"></canvas>
  </div>
</template>
<script setup>
import Chart from "chart.js/auto";
import { onMounted} from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: "line-chart",
  },
  height: {
    type: [Number, String],
    default: "170",
  },
  color: {
    type: String,
    default: "#ffffff", 
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Object,
      label: String,
      data: Array,
    },
  },
})
onMounted(() => {
  getChart()
  setTimeout(() => {
    getChart()
  }, 2000);
})

 
const getChart = () => { 
  const ctx = document.getElementById(props.id).getContext("2d");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }
  new Chart(ctx, {
    type: "line",
    data: {
      labels: props.chart.labels,
      datasets: [
        {
          label: props.chart.datasets.label,
          tension: 0,
          borderWidth: 0,
          pointRadius: 5,
          pointBackgroundColor: props.color,
          pointBorderColor: "transparent",
          borderColor: props.color,
          // eslint-disable-next-line no-dupe-keys
          borderColor: props.color,
          // eslint-disable-next-line no-dupe-keys
          borderWidth: 4,
          backgroundColor: "transparent",
          fill: true,
          data: props.chart.datasets.data,
          maxBarThickness: 6,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: props.color,
          },
          ticks: {
            display: true,
            color: props.color,
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            color: props.color,
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  });
}
</script>
