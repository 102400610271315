<template>
  <!-- :class="getRoute() === collapseRef ? `active bg-gradient-${color}` : ''"  -->
  <router-link
    :data-bs-toggle="collapse ? 'collapse' : ''"
    :to="collapse ? `#${collapseRef}` : collapseRef"
    :aria-controls="collapseRef"
    :aria-expanded="isExpanded"
    class="nav-link"
    :class="getRoute() === collapseRef ? `active active-theme` : ''" 
    v-bind="$attrs"
    @click="handleMenu"
  >
    <div
      class="text-center d-flex align-items-center justify-content-center"
      :class="isRTL ? ' ms-2' : 'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">{{
      navText
    }}</span>
  </router-link>
  <div :class="isExpanded ? 'collapse show' : 'collapse'">
    <slot name="list"></slot>
  </div>
</template>
<script setup>
import { ref, computed, getCurrentInstance } from "vue";;
const $app = getCurrentInstance().proxy

const props = defineProps({
  collapseRef: {
    type: String,
    required: true
  },
  navText: {
    type: String,
    required: true
  },
  collapse: {
    type: Boolean,
    default: true
  }
})
const 
  isRTL = computed(() => $app.$store.state.isRTL),
  color = computed(() => $app.$store.state.color),
  navbarMinimize = () => $app.$store.commit("navbarMinimize"),
  toggleSidebar = navbarMinimize,
  isExpanded = ref(false)
  

const 
  getRoute = () => {
    const routeArr = $app.$route.path.split("/");
    return routeArr[1];
  },
  handleMenu = () => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    isExpanded.value = !isExpanded.value
    
    if(screenWidth < 1200){
      toggleSidebar()
    }
  }
</script>
<style lang="scss">
@media screen and (max-width: 600px) {
  .nav-link{
    padding: 8px 12px !important;
  }
}
</style>
