import request from './request'

// 查询钱包列表
export function listWallet(query) {
  return request({
    url: '/product/wallet/list',
    method: 'get',
    params: query
  })
}

// 查询钱包详细
export function getWallet(id) {
  return request({
    url: '/product/wallet/' + id,
    method: 'get'
  })
}

// 查询钱包详细
export function getWalletByCurrency(currency) {
  return request({
    url: '/product/wallet/currency/' + currency,
    method: 'get'
  })
}


// 导出钱包
export function exportWallet(query) {
  return request({
    url: '/product/wallet/export',
    method: 'get',
    params: query
  })
}
