<template>
  <div class="container-fluid content-card">
    <div class="row align-items-center">
      <div class="col-lg-3 col-sm-8">
        <div class="nav-wrapper position-relative end-0 flex">
          <ul class="nav nav-pills nav-fill p-1" role="tablist">
            <li class="nav-item" v-for="(item, index) in naviList" :key="index" @click="handleCard(item.type)">
              <a class="nav-link mb-0 px-0 py-1  active active" data-bs-toggle="tab" href="" role="tab"
                aria-selected="true">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-2" v-loading="loading">
      <div class="col-lg-12 w-100 cardBar" style="min-height: 260px;">
        <!-- <el-scrollbar > -->
          <div class="row w-100 card-box" style="padding-top: 12px;" v-if="cardAllList">
            <div class="col-xl-6 card-item"  v-for="(item, index) in cardAllList" :key="index"
            :class="findCard(item)"
              @click="selectCard(item)">
              <el-popover
                  placement="top-start"
                  title="卡片信息(当前等级可用)"
                  :width="200"
                  trigger="hover"
                  :content="item.introduction"
                  :disabled="!findCard(item)"
                >
                <template #reference>
                  <master-card :cardNum='item.code' :cardAdd="item.cardArea" :cName="findCard(item,1)" :cardType='item.organization' :cardDesc='getExclusiveLabel(item.exclusive)' />
                </template>
              </el-popover>
            </div>
          </div>
        <!-- </el-scrollbar> -->
      </div>
    </div>
    <div class="d-flex mt-2 card-bot">
      <div class="left w-100 pt-3 pb-3" style="padding-right: 16px;">
        <div class="mt-3">
          <div class="d-flex justify-content-between left-wrap" v-if="cardVipList">
            <div class="row w-25 left-item" v-for="(item, index) in cardVipList" :key="index">
              <div class="col-12">
                <div class="card h-100" :class="item.id == vipSubscription.cardVipId ? 'card-border' : 'card-border'" style="overflow: hidden;">
                  <div class="pt-2 vip_fee">
                    <div class="border-radius-lg p-1">
                      <h6 class="text-capitalize text-center mb-0 text-white">
                        <div class="current_vip" v-if="item.id === vipSubscription.cardVipId">
                          <div class="current_vip_label">当前</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                          <div class="d-flex align-items-center justify-content-center" style="width: 24px;height: 24px;margin-right: 6px;">
                            <img class="w-100" style="width: 24px;height: 24px;" src="@/assets/img/avatar/Level0.svg" alt="个人用户" v-if="item.level == 0">
                            <img class="w-100" style="width: 24px;height: 24px;" src="@/assets/img/avatar/Level1.png" alt="个人专业" v-if="item.level == 1">
                            <img class="w-100" style="width: 24px;height: 24px;" src="@/assets/img/avatar/Level2.svg" alt="企业用户" v-if="item.level == 2">
                            <img class="w-100" style="width: 24px;height: 24px;" src="@/assets/img/avatar/Level3.svg" alt="合伙人" v-if="item.level == 3"> 
                          </div>
                          {{ item.name }}
                        </div>
                      </h6>
                    </div>
                  </div>
                  <div class="px-0 pt-2 pb-2">
                    <div class="table-responsive p-3 pt-0">
                      <div class="text-center">
                        <div class="py-3" v-if="item.isDefault === '1'">新用户默认</div>
                        <div class="py-3" v-else-if="item.customization">
                          <el-link :href="sys_cs_url" target="_blank" type="danger"
                            style="font-size: 14px;font-weight: bold; color: #7b809a;">详情咨询客服</el-link>
                        </div>
                        <div class="py-3" v-else>
                          <span ><span class="font-weight-bolder">$</span>{{ filterNumber(item.createFee) }}</span>
                          <span v-for="(d, i) in createTime" :key="i">
                            <span v-if="d.dictValue === item.createTimeUnit">/{{ d.dictLabel }}</span>
                          </span>
                        </div>
                        <div class="pb-3">
                          <div class="text-start pb-3" v-if="item.level > 0">初始卡位：
                            <span class="text-xl">{{ formatUnlimited(item.cardCapacity, '张（可增加）') }}</span>
                          </div>
                          <div class="text-start pb-3" v-else>初始卡位：
                            <span class="text-xl">{{ formatUnlimited(item.cardCapacity) }} 张</span>
                          </div>
                          <div class="text-start">服务费率：
                            <span class="text-xl" v-if="item.cardServiceFeeCoefficient > 0">{{ item.cardServiceFeeCoefficient }}%</span>
                            <span class="text-xl" v-else>定制化</span>
                          </div>
                        </div>
                        <div class="vip_label text-start pb-3">客服方式：<el-link :href="sys_cs_url" target="_blank" style="font-size: 13px">{{
                          item.serviceMode }}</el-link></div>
                        <div class="vip_label mb-5 text-start pb-3">在线客服：<span class="text-xl">{{ item.serviceLevel }}</span></div>
                        <div class="card_item_bottom">
                          <MaterialButton color="primary" class="primary-but" v-if="item.customization && item.level - 1 === vipSubscription.vipLevel" @click="openCS">成为合伙人</MaterialButton>
                          <MaterialButton v-else  :color="item.createFee > 0 ? 'primary':'dark'"  variant="gradient"
                                    :disabled="item.isDefault === '1' || item.customization || vipSubscription.vipLevel > item.level" @click="openVipOrder(item)"
                                    :class="( item.isDefault === '1' || item.customization || vipSubscription.vipLevel > item.level ) ? 'disabled-but' : 'primary-but'">
                                    <!-- <span v-if="item.isDefault === '1' || item.customization || vipSubscription.vipLevel > item.level || item.createFee > 0">1</span> -->
                            <span v-if="item.customization">{{smallLevelName(item)}}</span>
                            <span v-else>{{vipSubscription.cardVipId === item.id && item.isDefault === '0' ? '立即续费':'立即开通'}}</span>
                          </MaterialButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 续费方案 -->
    <el-dialog v-model="dialogVisible" :title="dialogTitle" width="600px" draggable>
      <div class="">
        <div class="title d-flex flex-wrap">
          <div style="margin-right: 1.5rem;">方案等级：<span class="theme-color">{{ vipSubscription.cardVipName }}</span></div>
          <div class="d-flex flex-wrap">到期时间：
            <span class="theme-color" v-if="vipSubscription.isForever === '1'">无限期</span>
            <span class="theme-color" v-else>{{ vipSubscription.expireDate }}</span>
          </div>
        </div>
        <el-divider></el-divider>
        <el-form ref="vipOrderForm" label-position="top" label-width="80px" :model="form">
          <el-form-item label="续费时长">
            <el-check-tag class="mt-2" checked>{{ form.duration }}</el-check-tag>
          </el-form-item>
          <el-form-item label="选择方案">
            <div class="d-flex flex-wrap">
              <div class="mt-2" v-for="(item, index) in cardVipList" :key="index">
                <el-check-tag style="margin-right: 20px;" :checked="item.id === form.cardVipId"
                  @change="handleVipType(item)" v-if="item.createDiscountFee > 0">
                  {{ item.name }}：<span class="font-weight-bolder">$</span>{{ filterNumber(item.createFee) }}
                </el-check-tag>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="企业名称" prop="company" v-if="form.enableSubset"
            :rules="[{ required: true, message: '请输入企业名称', trigger: ['blur'] }]">
            <el-input v-model="form.company" :disabled="!form.companyEdit" placeholder="请输入企业名称"></el-input>
          </el-form-item>
        </el-form>
        <el-row class="cashier_desk mt-4" type="flex">
          <el-col :span="8" class="divider">
            <div class="title">费用</div>
            <div class="">方案金额：<span class="font-weight-bolder">$</span>{{ filterNumber(form.price) }}</div>
            <div class="">折扣金额：<span class="font-weight-bolder">$</span>{{ filterNumber(form.discountFee) }}</div>
            <div class="">实付金额：<span class="font-weight-bolder">$</span>{{ filterNumber(form.actuallyFee) }}</div>
          </el-col>
          <el-col :span="16">
            <div class="payment_method">
              <div class="title">支付方式</div>
              <div class="pay_btn_group d-flex flex-wrap">
                <div class="mb-2" style="width: 130px;">
                  <material-button variant="gradient" color="primary" fullWidth style="padding: 10px;">钱包余额<span class="font-weight-bolder">$</span>{{ filterNumber(balance)}}</material-button>
                </div>
                <div  @click="toRecharge" class="mb-2" style="width: 130px;">
                  <material-button class="primary-but" variant="gradient" color="primary" fullWidth>快速充值</material-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <template #footer>
        <span class="dialog-footer d-flex justify-content-end">
          <div @click="dialogVisible = false" style="margin-right: 20px;width: 130px;">
            <material-button class="dark-but" style="padding: 9px;" variant="gradient" color="dark" fullWidth>取
              消</material-button>
          </div>
          <div style="min-width: 170px;" @click="dialogVisible = false">
            <material-button variant="gradient" class="primary-but" color="primary" fullWidth @click="submitVipOrder">立即支付（${{
              filterNumber(form.actuallyFee) }}）</material-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import setNavPills from "@/assets/js/nav-pills.js";
import MasterCard from "@/examples/Cards/MasterCard.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { Select } from '@element-plus/icons-vue'
import { onMounted, ref, getCurrentInstance, computed, watch, toRaw, markRaw  } from "vue";
const $app = getCurrentInstance().proxy

onMounted(() => {
  setNavPills();
  getCardVipSub()
})
const loading = ref(false)
const activeName = ref('first')

const findCard = (val,i) => {
  if(cardVip.value && cardVip.value.cardProductList && Array.isArray(cardVip.value.cardProductList)){
    let bol =  cardVip.value.cardProductList.find(r => r.code == val.code)
    if(val.exclusive){
      let type = new RegExp(val.exclusive).test($app.$store.state.user.cardExclusive)
      if(!type) return ""
    }
    if(bol && i) return 'card-mask'
    if(bol) return 'card-hover'
    return ''
  }
}
const handleTap = (event) => {
  console.log(event);
}
const handleOpenCard = () => {
  $app.$router.push({ name: "CardOpen" })
}
const naviList = ref([{ name: '所有', type: 'ALL' }, { name: 'Mastercard', type: "Master" + ' ' + "Card" }, { name: 'Visa', type: 'Visa' }])
const cardList = ref([])
const handleCard = (i) => {
  if(i != 'ALL'){
    cardAllList.value = cardList.value.filter(r => r.organization == i)
  }else{
    cardAllList.value = cardList.value
  }
  console.log(cardAllList);
}
const cardVip = ref([])
const getCardVipSub = () => {
  loading.value = true
  $app.$http.shop.getApiCardVipSubscription().then(res => {
    cardVip.value = res.data
    loading.value = false
  })
}
const selectCardData = ref(null)
const selectCard = (v) => {
  selectCardData.value = v
}
const wallet = ref({})
$app.$http.wallet.getWalletByCurrency("USD").then(res => {
  wallet.value = res.data
})
const price = ref(null)
const tradePrice = computed(() => Number(price.value) + Number(selectCardData.value ? selectCardData.value.price : 0))

watch(price, (n, o) => {
  if (isNaN(n)) price.value = o
  else price.value = Number(price.value)
})


const cardVipList = ref([])
const cardAllList = ref([])
const cardRateHead = ref([])
const cardRateList = ref([])
const vipSubscription = ref({
  isForever: '1'
})
const createTime = ref([])
const exclusive = ref([])
const sys_cs_url = ref('')
const dialogVisible = ref(false)
const form = ref({
  cardVipId: 2,
  paymentMethod: 0,
  duration: '1月',
  company: '',
  price: 0,
  discountFee: 0,
  actuallyFee: 0,
  enableSubset: false,
  companyEdit: true,
})
const balance = ref('')
const vipOrderForm = ref()
const submitLoading = ref(false)

onMounted(() => {
  $app.$http.config.getConfigKey('sys_cs_url').then(res => {
    sys_cs_url.value = res.msg;
  })
  getData()
})

const getData = () => {
  $app.$store.commit("getUser")
  $app.$http.shop.getApiCardVipList().then(res => {
    cardVipList.value = res.rows
    cardAllList.value = res.rows[2].cardProductList
    cardList.value = cardAllList.value
    cardRateHead.value = []
    cardRateList.value = []
    res.rows.map(c => {
      let obj = { 'name': c.name, 'customization': c.customization };
      c.cardProductList.map(p => {
        if (cardRateHead.value.findIndex(f => f.prop === p.code) < 0) {
          let label = p.code;
          if (p.exclusive) {
            label += '(' + getExclusiveLabel(p.exclusive) + ')';
          }
          cardRateHead.value.push({ prop: p.code, label: label })
        }
        obj[p.code] = '$' + p.price;
      })
      cardRateList.value.push(obj);
    })
    cardRateHead.value.map(h => {
      cardRateList.value.map(r => {
        if (!r[h.prop]) {
          r[h.prop] = r.customization ? '定制化' : '无权限'
        }
      })
    })
  })
  $app.$http.wallet.getWalletByCurrency("USD").then(res => {
    balance.value = res.data.balance;
  })
  $app.$http.shop.getApiCardVipSubscription().then(res => {
    vipSubscription.value = res.data
  })
  $app.$http.dict.getDicts('card_vip_create_time_unit').then(res => {
    createTime.value = res.data
  })
  $app.$http.dict.getDicts('card_exclusive_type').then(res => {
    exclusive.value = res.data
  })
}

const getExclusiveLabel = (val) => {
  if (val) {
    let dict = exclusive.value.find(item => item.dictValue === val)
    return dict ? dict.dictLabel : ""
  }
}
const formatUnlimited = (val, unit) => {
  if (Number(val.toString().replace(/[^0-9.]+/, '')) > 0) {
    return val + (unit ? unit : '')
  }
  return '无限制'
}
const simpleAmount = (val) => {
  if (val >= 1000) {
    return val / 1000 + 'K';
  }
  return val;
}
const smallLevelName = (item) => {
  let obj = cardVipList.value.find(r => r.level === (item.level - 1));
  return '请先升级为' + obj.name;
}
const openCS = () => {
  window.open(sys_cs_url)
}

const openVipOrder = (item) => {
  console.log(item);
  if (vipSubscription.value.cardVipId === item.id && item.isDefault === '0') {
    dialogTitle.value = '续费方案'
  } else {
    dialogTitle.value = '开通方案'
  }
    
  if (item == null) {
    cardVipList.value.some(r => {
      if (r.createDiscountFee > 0) {
        item = r;
        return true;
      }
    })
  }
  dialogVisible.value = true
  handleVipType(item)
}

const handleVipType = (item) => {
  form.value.cardVipId = item.id
  let unit = toRaw(createTime.value.filter(r => r.dictValue === item.createTimeUnit))[0]
  form.value.duration = '1' + unit.dictLabel;
  form.value.price = item.createFee; // 原价
  form.value.actuallyFee = item.createDiscountFee; //实付金额
  form.value.discountFee = item.createFee - item.createDiscountFee; // 折扣金额
  form.value.enableSubset = item.enableSubset;
  dialogName.value = item.name + ' ' + '$' + item.createFee + '/' +  unit.dictLabel
}

const submitVipOrder = () => {
  vipOrderForm.value.validate(valid => {
    if (valid) {
      $app.$confirm(`是否确认升级${dialogName.value}`,'温情提示').then(() => {
        if (form.value.actuallyFee > balance.value) {
          $app.$notify.error({
            title: '钱包余额不足',
            dangerouslyUseHTMLString: true,
            message: '<p>余额: $' + balance.value + '</p><p>费用: $' + form.value.actuallyFee + '</p>'
          });
          return;
        }
        submitLoading.value = true;
        $app.$http.shop.setVipOrder(form.value).then(res => {
          open.value = false;
          if (form.value.enableSubset) {
            $app.$alert('恭喜您成为企业用户，重新登录后享更多特权！', '续费成功', {
              confirmButtonText: '重新登录',
              type:'success',
              callback: () => {
                $app.$store.commit('setToken', null)
                $app.$router.push({ name: "SignIn" })
              }
            });
            getData();
          } else {
            $app.$message.success("续费成功")
            getData();
          }
        }).finally(f => {
          submitLoading.value = false;
        });
      })
    }
  });
}
const dialogTitle = ref('')
const dialogName = ref('')
const toRecharge = () => {
  $app.$router.push({ name: "Recharge" })
}
</script>
<style lang="scss" scoped>
.w-25 {
  width: 25%;
}

.vip-border {
  // border: 1px solid #e3306f;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(227, 48, 111, 0.6);
}

.card-border {
  // border: 1px solid #5864c4;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(88, 100, 196, 0.6);
}

.card_bean_plane {
  border-radius: 3px;
  border: 1px solid #979797;
  font-size: 13px;
  padding: 15px 5px;
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  position: relative;

  .el-divider--horizontal {
    position: absolute;
    top: -1px;
    margin: 0;
    height: 0;
    border-top: 0;

    .el-divider__text.is-left {
      left: 5px;
    }

    .el-divider__text {
      padding: 0 10px;
      font-size: 13px;
    }
  }

  .bean_list {
    line-height: 1.5em;
    width: 100%;
  }
}

.vip_fee {
  text-align: center;
  background-color: #4b4f59;
  padding: 10px 0;
  font-weight: bold;
  position: relative;
}

.card_item_bottom {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}

.current_vip {
  overflow: hidden;
  position: absolute;
  width: 85px;
  height: 85px;
  z-index: 10;
  top: 0;
  right: 0;

  .current_vip_label {
    background-color: #52ac56;
    color: white;
    width: 127px;
    padding: 3px 0;
    text-align: center;
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    right: -45px;
    font-size: 12px;
    font-weight: bold;
  }
}

//
.content-card {
  // max-height: calc(100vh - 120px - 1.5rem);
  // height: calc(100vh - 120px - 1.5rem);
  max-width: 1300px;
  overflow: hidden;
}

.el-input {
  --el-input-focus-border-color: #e3306f;
}

.el-tabs {
  --el-color-primary: #e3306f;
}

.card-box {
  display: flex;
  flex-wrap: wrap;
  .card-item {
    transition: transform 0.3s ease;
    cursor: pointer;
    width: 20%;
    min-width: 240px;
    margin-bottom: 1rem;
    // max-width: 320px;
  }
  .card-hover{
    
  }
  .card-hover:hover {
    transform: scale(1.1);
  }
}
.cashier_desk {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 10px 20px;

  .divider {
    border-right: 1px solid #DCDFE6;
  }

  .payment_method {
    padding-left: 30px;

    .pay_btn_group {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .title {
    color: #606266;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
  }

  .price {
    font-size: 13px;
    color: #FF0000;
    line-height: 19px;
  }
}
@media screen and (min-width: 621px) {
  .content-card{
    position: relative;
    padding: 0 1.5rem !important;
  }
  // .card-bot{
  //   z-index: 9;
  //   position: absolute;
  //   bottom: 0;
  //   left: 1.5rem;
  //   right: 1.5rem;
  //   background: #f0f2f5;
  // }
}

@media screen and (max-width: 620px) {
  .row{
    --bs-gutter-x:0;
  }
  .left {
    padding-right: 0 !important;
    flex-wrap: wrap !important;

    .left-wrap {
      flex-wrap: wrap;
      .w-25 {
        width: 100% !important;
        margin-bottom: 3rem;
      }
    }
  }

  

  .content-card {
    max-height: none;
    height: auto;
  }

  .card-box {
    justify-content: space-between;

    .card-item {
      width: 48%;
      min-width: 0;
      margin-bottom: 10px;
    }
  }

  .card-bot {
    flex-wrap: wrap;

    .left {
      width: 100% !important;
    }

    .right {
      width: 100% !important;
    }
  }
}
</style>
